
import { defineComponent, PropType } from "@vue/composition-api";
import Vue from "vue";

const showItems = 2;
export default defineComponent({
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      showItems: 0,
    };
  },
  computed: {
    tooltipText(): string[] {
      if (!Array.isArray(this.value)) {
        return [""];
      }
      const elements = this.value.slice(showItems, this.value.length).reduce((res: Record<string, string>, item: string) => {
        res[item] = "Название";
        return res;
      }, {});

      this.items.forEach((item: { value: string; title: string }) => {
        if (elements[item.value]) {
          elements[item.value] = item.title;
        }
      });
      return Object.values(elements);
    },
    isClearable(): boolean {
      return Array.isArray(this.value) ? this.value.length > 1 : !this.isChips;
    },
  },
  props: {
    isShowSelectAllBtn: {
      type: Boolean,
      default: false,
    },
    isDeleteableChips: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    attach: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Array, String, Object, Number],
      default: () => [],
    },
    zIndex: {
      type: [Number, String],
    },
    id: {
      type: String,
      default: "",
    },
    isOptionsTopPosition: {
      type: Boolean,
      default: false,
    },
    isBaseBorder: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<{ value: string; title: string }[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    defaultShowItems: {
      type: Number,
      default: showItems,
    },
    isReturnObject: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isChips: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.showItems = this.defaultShowItems;
  },
  methods: {
    deleteItem(item: string) {
      this.$emit("change", Array.isArray(this.value) ? this.value.filter((selectItem) => selectItem !== item) : "");
    },
    deleteCardItem(id: number | string) {
      this.$emit("change", Array.isArray(this.value) ? this.value.filter((selectItem: { id: number | string }) => selectItem.id !== id) : "");
    },
    showAllChips() {
      this.showItems = this.value.length;
      Vue.nextTick(() => {
        this.$emit("updateHeight");
      });
    },
    resetShowItems() {
      this.showItems = this.defaultShowItems;
    },
    changeValue(val: string) {
      this.$emit("change", val || []);
    },
  },
});
